<template>
  <div class="footer-box">
    <div class="footer rule-limit">
      <div class="rule-width flex flex-sb flex-align">
        <div>
          <h2 class="model-title"
              @click="$router.push({name:'cloudnotes'})">CLOUDNITES</h2>
          <div class="content flex flex-col flex-wrap">
            <div v-for="(v,i) in page"
                 :key="i">
              <h5 @click="$router.push({name:v.name})">{{v.title}}</h5>
            </div>
            <!-- <p>了解更多</p> -->
          </div>
        </div>
        <div class="flex tx-c">
          <div v-for="(v,i) in code"
               class="code-box"
               :key="i">
            <div class="code rule-img">
              <img :src="v.img_info"
                   alt="">
            </div>
            <h5>{{v.title}}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="rule-width">
        <div>
          <span>电话：400-611-3066</span>
          <span>客服时间：09:00-18:00</span>
          <span>E-mail：contact@cloudnotes.com.cn</span>
          <span>地址：上海市杨浦区国霞路258号绿地双创中心5楼</span>
        </div>
        <div class="bottom flex flex-align">
          <span>Copyright © 2021上海培羽教育科技有限公司</span>
          <img src="@/assets/img/hu.png"
               alt=""
               style="width:20px;height:20px;margin-right:6px">
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005009"
             target="_brank">

            <span>沪公网安备 31011002005009</span>
          </a>

          <a href="https://beian.miit.gov.cn/#/Integrated/index"
             target="_brank">
            <span>沪ICP备18025925号-1</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import code1 from '@/assets/img/downR1.png'
import code2 from '@/assets/img/downR2.png'
import code3 from '@/assets/img/downR4.png'
import code4 from '@/assets/img/downR5.png'
export default {
  data() {
    return {
      formData: {},
      page: [
        { title: '多元产品', name: 'product' },
        { title: '教学体系', name: 'teachers' },
        { title: '活动资讯', name: 'activity' },
        { title: '关于我们', name: 'us' },
        { title: 'APP下载', name: 'down' },
      ],
      code: [
        { img_info: code1, title: 'Ios下载' },
        { img_info: code2, title: 'Android下载' },
        { img_info: code3, title: 'Ios下载' },
        { img_info: code4, title: 'Android下载' },
      ],
    }
  },
  beforeMount() {
    // this.getData()
  },
  methods: {
    getData() {
      this.$http.getConfig().then((res) => {
        this.formData = res
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-box {
  background: rgba($color: #000000, $alpha: 0.8);
  color: #fff;
  .footer {
    .content {
      height: 150px;
      margin-top: 20px;
      h5 {
        margin-right: 90px;
        line-height: 24px;
        margin-bottom: 12px;
        color: rgba($color: #fff, $alpha: 0.8);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .code-box {
      margin-left: 40px;
      .code {
        width: 145px;
        height: 145px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 12px;
      }
    }
  }
  .copyright {
    width: 100%;
    padding: 30px 0 40px;
    border-top: 1px solid #818181;
    span {
      font-size: 12px;
      margin-right: 30px;
    }
    .bottom {
      color: #9f9f9f;
      margin-top: 20px;
    }
  }
}
</style>