<template>
  <div class="relation-box flex flex-r flex-ae">
    <!-- 领取课程 -->
    <GetCourse ref="GetCourse"></GetCourse>

    <div class="right-btn">
      <div class="btn">
        <img src="@/assets/img/relbtn1.png"
             alt="">
        <p>智能客服</p>
      </div>
      <div class="btn"
           @click="$refs.GetCourse.classBox = true">
        <img src="@/assets/img/relbtn2.png"
             alt="">
        <p>领取课程</p>
      </div>
      <div class="btn"
           @click="setTop()">
        <img src="@/assets/img/relbtn3.png"
             alt="">
        <p>置顶</p>
      </div>
    </div>
  </div>
</template>
<script>
import GetCourse from '@/components/relationBox/components/getCourse'
export default {
  data() {
    return {}
  },
  components: {
    GetCourse,
  },
  methods: {
    setTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.relation-box {
  position: fixed;
  right: 24px;
  bottom: 24px;

  .right-btn {
    margin-left: 40px;
    .btn {
      width: 72px;
      height: 72px;
      background: rgba(255, 255, 255, 0.8);
      border: 0.5px solid #cccccc;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      margin-bottom: 16px;
      img {
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>