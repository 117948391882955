<template>
  <div class="form-box pos-rel"
       v-if="classBox">
    <div class="form-box bg pos-abs"></div>
    <img class="close"
         @click="close()"
         src="@/assets/img/relbtn4.png"
         alt="">
    <h3>领取体验课</h3>
    <div class="input">
      <input type="text"
             placeholder="孩子姓名"
             v-model="form.name">
    </div>
    <div class="input">
      <input type="number"
             min="0"
             placeholder="年龄"
             v-model="form.age">
    </div>
    <div class="input">
      <input type="text"
             placeholder="联系电话"
             v-model="form.mobile">
    </div>
    <div class="input flex flex-sb">
      <input class="code"
             type="text"
             placeholder="验证码"
             v-model="form.code">
      <p class="code-btn"
         @click="codeFont == '获取验证码' ? codeBtn() :''">{{codeFont}}</p>
    </div>
    <img class="submit"
         @click="submitBtn"
         src="@/assets/img/relbtn5.png"
         alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      classBox: false,
      form: {
        name: '',
        age: '',
        code: '',
        mobile: '',
      },
      codeFont: '获取验证码',
      timer: null,
    }
  },
  methods: {
    close() {
      this.classBox = false
      if (this.$parent.$parent.courseBox) {
        this.$parent.$parent.courseBox = false
      }
    },
    codeBtn() {
      if (!/^1([3456789])\d{9}$/.test(this.form.mobile)) {
        alert('请填写正确的手机号!')
        return false
      }
      if (!this.timer) {
        this.codeFont = 60

        this.timer = setInterval(() => {
          if (this.codeFont > 0 && this.codeFont <= 60) {
            this.codeFont--
          } else {
            this.codeFont = '获取验证码'
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)

        this.$http
          .sendSMSCode({
            mobile: this.form.mobile,
          })
          .then(() => {})
      }
    },
    submitBtn() {
      if (this.form.name.length > 5 || this.form.name.length < 2) {
        alert('请填写正确的姓名!')
        return false
      }
      if (this.form.age == '') {
        alert('请填写正确的年龄!')
        return false
      }
      if (this.form.age <= 0 || this.form.age > 99) {
        alert('请填写正确的年龄!')
        return false
      }
      if (!/^1([3456789])\d{9}$/.test(this.form.mobile)) {
        alert('请填写正确的手机号!')
        return false
      }
      if (this.form.code.length === 0) {
        alert('请填写验证码!')
        return false
      }
      this.$http.postSubscribe(this.form).then(() => {
        alert('提交成功！')
        this.form = {
          name: '',
          age: '',
          code: '',
          mobile: '',
        }
        this.classBox = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-box {
  width: 270px;
  height: 370px;
  background: #ffffff;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 24px;
  &.bg {
    z-index: -1;
    left: 10px;
    top: -10px;
    background: none;
    border: 1px solid #ff3d83;
  }
  .close {
    cursor: pointer;
    float: right;
    margin-right: -8px;
    margin-top: -8px;
  }
  h3 {
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
  .input {
    width: 100%;
    border-bottom: 1px #ccc solid;
    padding: 8px 0;
    margin-bottom: 20px;
    input {
      border: none;
      outline: none;
      font-size: 16px;
    }
    .code {
      width: 50%;
    }
    .code-btn {
      white-space: nowrap;
      cursor: pointer;
      color: #ff3d83;
    }
  }
  .submit {
    display: block;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>