<template>
  <div>
    <div class="header-bg one"
         v-if="scrollFlag && !bgColor"
         ref="headerBg">
      <div class="content flex flex-align flex-sb">
        <div class="logo"
             @click="$router.push({name:'cloudnotes'})">
          <img src="../../assets/img/logo.png"
               alt="">
        </div>
        <div class="page flex">
          <div v-for="(v,i) in constantRoutes"
               :key="i">
            <p v-if="!v.meta.hidden"
               :class="active == v.meta.show? 'active':''"
               class="p flex flex-align tx-c"
               @click="$router.push({name:v.name})">
              <span>{{v.meta.title}}</span>
            </p>
          </div>
        </div>
        <div class="rest flex">
          <p :class="active === 'down'? 'active':''"
             class="flex flex-align tx-c"
             @click="more()">
            <span>APP下载</span>
          </p>
          <p class="photo flex flex-align tx-c">
            <img src="@/assets/img/phone.png"
                 alt="">
            <span>400-611-3066</span>
          </p>
        </div>
      </div>
    </div>
    <!-- fadeIn  fadeInDown-->
    <div class="header-bg two animated fadeIn"
         v-if="!scrollFlag || bgColor"
         ref="headerBg2">
      <div class="content flex flex-align flex-sb">
        <div class="logo"
             @click="$router.push({name:'cloudnotes'})">
          <img src="../../assets/img/logo.png"
               alt="">
        </div>
        <div class="page flex">
          <div v-for="(v,i) in constantRoutes"
               :key="i">
            <p v-if="!v.meta.hidden"
               :class="active == v.meta.show? 'active':''"
               class="p flex flex-align tx-c"
               @click="$router.push({name:v.name})">
              <span>{{v.meta.title}}</span>
            </p>
          </div>
        </div>
        <div class="rest flex">
          <p :class="active === 'down'? 'active':''"
             class="flex flex-align tx-c"
             @click="more()">
            <span>APP下载</span>
          </p>
          <p class="photo flex flex-align tx-c">
            <img src="@/assets/img/phone.png"
                 alt="">
            <span>400-611-3066</span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="bgColor"
         style="width:100%;height:70px"></div>
  </div>
</template>

<script>
import { constantRoutes } from '@/router/index.js'
export default {
  data() {
    return {
      active: 'produce',
      constantRoutes,
      scroll: 0,
      scrollNum: 200,
      scrollFlag: true,
    }
  },
  props: {
    bgColor: {
      default: false,
    },
  },
  created() {
    if (this.$route.meta) {
      this.active = this.$route.meta.show
    }
  },
  watch: {
    scroll: {
      handler(val) {
        if (val > this.scrollNum) {
          this.scrollFlag = false
        } else {
          this.scrollFlag = true
        }
      },
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScrollTop)
  },
  methods: {
    more() {
      this.active = 'down'
      this.$router.push({ name: 'down' })
    },
    handleScrollTop() {
      let b =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop //  获取窗口滚动条高度
      this.scroll = b
    },
  },
}
</script>

<style lang="scss">
.header-bg {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 999;
  &.two {
    background: rgba($color: #000000, $alpha: 0.8);
  }
  .content {
    height: 100%;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    .logo {
      height: 100%;
      cursor: pointer;
      img {
        height: 38px;
        margin-top: 14px;
      }
    }
    .page {
      width: 650px;
    }
    p {
      padding: 0 20px;
      font-size: 14px;
      color: #e2e2e2;
      cursor: pointer;
      span {
        height: 26px;
        margin-top: 8px;
      }
      &.active span {
        border-bottom: 2px solid #fff;
        color: #fff;
      }
      &:hover {
        color: #fff;
      }
    }
    .rest {
      .photo {
        cursor: default;
        img {
          margin-top: 6px;
          margin-right: 8px;
        }
        &:hover {
          background: none;
        }
      }
    }
  }
}
</style>