<template>
  <div>
    <Header :bgColor="bgColor"></Header>
    <div style="overflow: hidden;">
      <loading v-if='LOADING' />
      <slot />
      <Footer></Footer>
    </div>
    <RelationBox></RelationBox>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import loading from '../loading/loading.vue'
import Header from '../header/index'
import Footer from '../footer/index.vue'
import RelationBox from '../relationBox/index.vue'
export default {
  components: {
    Header,
    Footer,
    loading,
    RelationBox,
  },
  props: {
    bgColor: {
      default: false,
    },
  },
  computed: {
    ...mapState(['LOADING']),
  },
}
</script>

